<template>
  <div class="d-flex align-items-center justify-content-center p-4">
    <b-card class="col-md-6 col-lg-6 col-xl-4 px-0">
      <form @submit.prevent autocomplete="off">
        <validation-observer ref="socialMediaAuthenticationRules">
          <div>
            <h3 class="text-xl font-weight-semibold">
              <span>{{ $t("common.please_insert_document") }}</span>
            </h3>
            <p class="linktext-subdued">
              <a href="#" v-b-tooltip.hover :title="$t('common.creation_garantee')" >
                {{ $t("common.why_we_need_that") }}
              </a>
            </p>

            <!-- @todo validar regra pois atualmente a consulta e feita apenas usando o cpf, por isso deixei fixo para nao pesquisar vazio-->
            <form-field-input
              name="curriculum.personal.cpf"
              v-model="curriculum.personal.cpf"
              mask="###.###.###-##"
              label-class="font-weight-semibold"
              inputmode="decimal"
              rules="required|cpf"
              v-show="!is_foreigner"
            />

            <form-field-input
              name="curriculum.personal.primary_email"
              v-model="curriculum.personal.primary_email"
              label-class="font-weight-semibold"
              inputmode="email"
              type="email"
              :rules="is_foreigner ? 'required|email' : ''"
              v-show="is_foreigner"
            />

            <form-field-switch
              name="user.login.is_foreigner"
              v-model="is_foreigner"
              label-class="font-weight-semibold"
              autocomplete="off"
              v-if="company.config.flags.foreigner_registration"
            />

            <div class="d-flex flex-column gap-6 text-center">
              <b-button variant="primary" pill block @click="submit()">
                {{ $t("common.validate_informations") }}
              </b-button>
            </div>
          </div>
        </validation-observer>
      </form>
    </b-card>
  </div>
</template>

<script>
import companyMixin from "@state/company";
import { notify } from "@/plugins/notification";
import * as FormComponents from "@components/forms";
import { useCurriculum } from "@state/user/curriculum";
import { useChangeable } from "@state/behaviours/changeable";
import { useConfiguration } from "@state/user/configuration";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [companyMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    ...FormComponents,
  },
  setup() {
    const curriculum = useCurriculum();
    const configuration = useConfiguration();
    const changeable = useChangeable();

    return {
      changeable,
      curriculum,
      configuration,
    };
  },
  props: {
    social_media: String,
    hash: String,
  },
  data() {
    return {
      stateSubscribe: null,
      is_foreigner: false,
    };
  },
  created() {
    this.curriculum.resetState();

    this.stateSubscribe = this.changeable.$subscribe((mutation, state) => {
      const poll_changes = this.changeable.poolChanges("curriculum");
      this.changeCounter = poll_changes.counter;

      if (poll_changes.counter >= 1) {
        this.isSaving = true;
        this.changeable.localPersist("changeable-express-form");
        this.changeCounter = 0;
      }
    });
  },
  destroyed() {
    this.stateSubscribe();
  },
  methods: {
    submit() {
      this.$refs.socialMediaAuthenticationRules.validate().then((success) => {
        this.$root.loading = true;
        if (success) {
          // VERIFICAR SE CPF JÁ EXISTE, E CASO POSITIVO, NEGAR ACESSO
          this.curriculum.checkRegistration({ cpf: this.curriculum.personal.cpf})
            .then((response) => {
              if (response.data.success) {
                this.$router.push({name: "register2", query: { create_curriculum: 'yes' }});
              } else {
                notify({title: "$t:errors.title.danger", message: "$t:errors.link_account.already-existent", variant: "danger"});
              }
            })
            .catch((error) => {
              notify({title: "$t:errors.title.danger", message: "$t:errors.registration.message", variant: "danger"});
            })
            .finally(() => {
              this.$root.loading = false;
            });
        }
      })
      .finally(() => {
        this.$root.loading = false;
      })
    },
  },
};
</script>
