<template>
  <div>
    <form-set-generic
      v-if="section.show"
      v-model="curriculum.professional"
      form="curriculum"
      section="professional"
      :fields="section.fields"
      :title="$t('curriculum.sections.professional.objectives')"
      autocomplete="off"
    >

    <template #intended_occupation="{ model, field, componentName, testId, component }">
      <span>
        <component
          ref="field-intended_occupation"
          :is="component.is"
          :name="componentName"
          :test-id="testId"
          v-model="model[field]"
          v-bind="component"
          :reduce="(option) => option.label || option"
          :multiple="true"
          :selectable="(option) => curriculum.professional.intended_occupation.length < 3"
          @search="searchOccupations"
          :options="occupationOptions"
          autocomplete="off"
        >
          <template #no-options="{ search, searching }">
            <span v-if="isLoadingOccupations">{{ $t("common.loading") }}...</span>
            <span v-else-if="searching">
              {{ `${$t("common.occupation_not_found")} "${search}"` }}.
              <a href="#" @click.prevent="addOccupation(search)">
                {{ $t("common.click") }}
              </a>
              {{ $t("common.to_add") }}
            </span>
            <span v-else>
              {{ $t("common.no-results-found") }}
            </span>
          </template>
        </component>
      </span>
    </template>
    </form-set-generic>

    <form-set-professional-experience
      form-id="curriculum"
      name="curriculum.experiences"
      v-model="curriculum.experiences"
      :fields="section.experiences.fields"
      section="experiences"
      v-if="curriculum.professional.has_professional_history == 'y'"
      :title="$t('curriculum.sections.professional.experiences')"
      class="profile-subsection"
    />
  </div>
</template>

<script>
import * as _ from "lodash-es";
import { useDomain } from "@state/domain/domain";
import * as FormComponents from "@components/forms";
import { useCurriculum } from "@state/user/curriculum";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import FormSetProfessionalExperience from "@components/forms/set/ProfessionalExperience.vue";

export default {
  components: {
    FormSetGeneric,
    FormSetProfessionalExperience,
    ...FormComponents,
  },
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.occupationOptions = this.domain.occupations || [];
  },
  setup() {
    const curriculum = useCurriculum();
    const domain = useDomain();

    return {
      // you can return the whole store instance to use it in the template
      curriculum,
      domain,
    };
  },
  data() {
    return {
      isLoadingOccupations: false,
      occupationOptions: [],
    };
  },
  methods: {
    addOccupation(name) {
      this.domain.create_record("occupations", { label: name })
        .then((response) => this.occupationOptions = [response])
    },
    searchOccupations: _.debounce(function (data, loading) {
      if (data.length > 2) {
        this.isLoadingOccupations = true
        this.domain.getOccupations({ param: data })
          .then((response) => {
            this.occupationOptions = response.data || [];
          })
          .finally(() => this.isLoadingOccupations = false);
      }
    }, 300),
  },
};
</script>
