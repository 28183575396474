<template>
  <validation-provider
    #default="{ errors }"
    :name="label"
    :vid="vid"
    :rules="rules"
  >
    <div
      class=""
      header-class="d-flex w-100 justify-content-between align-items-center"
    >
      <div
        class="card border-radius-base mt-2"
        v-for="(pp, index) in computedValue"
        :key="index"
      >
        <div class="card-body">
          <div class="flex-row">
            <span
              class="title text-lg font-weight-semibold flex justify-content-between"
            >
              <span>
                {{  getOccupationName(pp) }}
              </span>
              <span>
                <PencilAltIcon
                  v-on:click="handleEdit(pp, index)"
                  class="text-primary cursor-pointer"
                />
                <TrashIcon
                  v-on:click="remove(pp, index)"
                  class="text-primary cursor-pointer"
                />
              </span>
            </span>
          </div>
          <p class="text-sm">
            {{ pp.company_name }}
          </p>
          <span class="text-sm font-weight-medium">{{
            $t("common.period")
          }}</span>
          <br />
          <p class="font-weight-medium">
            {{ formatDate(pp.start) }}
            <span v-if="pp.finish && !pp.current_job">
              {{ " - " + formatDate(pp.finish) }}
            </span>
            <span v-else>
              {{ " - " + $t("common.present") }}
            </span>
          </p>
          <div v-if="pp.acting_area">
            <span class="text-sm font-weight-medium">{{
              $t("curriculum.professional.experiences.acting_area.label")
            }}</span>
            <br />
            <p class="font-weight-medium">{{ pp.acting_area }}</p>
          </div>
          <div v-if="pp.company_size">
            <span class="text-sm font-weight-medium">{{
              $t("curriculum.professional.experiences.company_size.label")
            }}</span>
            <br />
            <p class="font-weight-medium">{{ pp.company_size }}</p>
          </div>
          <div v-if="pp.last_salary">
            <span class="text-sm font-weight-medium">{{
              $t("curriculum.professional.experiences.last_salary.label")
            }}</span>
            <br />
            <p class="font-weight-medium">{{ toCurrency(pp.last_salary) }}</p>
          </div>
          <span class="text-sm font-weight-medium">{{
            $t("curriculum.professional.experiences.activities.label")
          }}</span>
          <br />
          <span class="text-sm font-weight-medium">{{ pp.activities }}</span>
        </div>
      </div>

      <giga-button
        :label="$t('curriculum.actions.professional.add_experience')"
        @click="add"
      />
    </div>

    <b-modal
      ref="modal"
      id="professional-experiences-modal"
      size="lg"
      centered
      :title="$t('curriculum.actions.professional.add_experience')"
      @ok="append($event)"
      @cancel="clear"
      scrollable
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button
          class="border"
          variant="outline-primary"
          pill
          size="sm"
          @click="cancel()"
        >
          {{ $t("common.cancel") }}
        </b-button>
        <b-button variant="primary" pill size="sm" @click="ok()">
          {{ $t("common.save") }}
        </b-button>
      </template>

      <validation-observer ref="entityRules" tag="div" class="">
        <form-set-generic
          section="professional.experiences"
          v-model="new_entity"
          :fields="internalFields"
          autocomplete="off"
          :field-props="{
            untracked: true,
          }"
        >
          <template
            #occupation_id="{ model, field, componentName, testId, component }"
          >
            <span>
              <component
                ref="field-occupation_id"
                :is="component.is"
                :name="componentName"
                :test-id="testId"
                :reduce="(option) => option.label || option"
                v-model="model[field]"
                v-bind="component"
                @search="searchOccupations"
                :options="occupationOptions"
              >
                <template #no-options="{ search, searching }">
                  <span v-if="isLoadingOccupations">{{ $t("common.loading") }}...</span>
                  <span v-else-if="searching">
                    {{ `${$t("common.occupation_not_found")} "${search}"` }}.
                    <a href="#" @click.prevent="addOccupation(search)">
                      {{ $t("common.click") }}
                    </a>
                    {{ $t("common.to_add") }}
                  </span>
                  <span v-else>
                    {{ $t("common.no-results-found") }}
                  </span>
                </template>
              </component>
            </span>
          </template>

          <template
            #finish="{ model, field, componentName, testId, component }"
          >
            <span>
              <component
                ref="field-finish"
                v-if="!model['current_job']"
                :is="component.is"
                :name="componentName"
                :test-id="testId"
                v-model="model[field]"
                v-bind="component"
                autocomplete="off"
              >
              </component>
            </span>
          </template>
        </form-set-generic>
      </validation-observer>
    </b-modal>
  </validation-provider>
</template>

<script>
import * as _ from "lodash-es";
import FormFieldSet from "../mixins/Set";
import FormFieldBase from "../mixins/Base";
import { isoToLocal } from "@/plugins/i18n";
import * as FormComponents from "@components/forms";
import GigaButton from "@components/buttons/GigaButton.vue";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import { PencilAltIcon, TrashIcon } from "@vue-hero-icons/outline";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [FormFieldBase, FormFieldSet],
  components: {
    TrashIcon,
    GigaButton,
    PencilAltIcon,
    FormSetGeneric,
    ...FormComponents,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    return {
      formatDate: isoToLocal,
    };
  },
  data() {
    return {
      isLoadingOccupations: false,
      occupationOptions: [],
    };
  },
  created() {
    this.occupationOptions = this.domain.occupations || [];
  },
  methods: {
    addOccupation(name) {
      this.domain.create_record("occupations", { label: name })
        .then((response) => this.occupationOptions = [response])
    },
    addCompany(name, refName) {
      this.domain.create_record("companies", { label: name }).then((record) => {
        this.new_entity.company_id = record.value;
        this.$refs[refName].selectValue();
      });
    },
    toCurrency(data) {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(data);
    },
    searchOccupations: _.debounce(function (data, loading) {
      if (data.length > 2) {
        this.isLoadingOccupations = true
        this.domain.getOccupations({ param: data })
          .then((response) => {
            this.occupationOptions = response.data || [];
          })
          .finally(() => this.isLoadingOccupations = false);
      }
    }, 300),
    handleEdit(item, index) {

      // Reset old state
      this.occupationOptions = [];

      /**
       * Overwrite occupation_id, because occupation_id could be id or name
       */
      item.occupation_id = this.getOccupationName(item);
      item.occupation_name = null;

      this.edit(item, index)
    },
    getOccupationName(item) {
      return !_.isEmpty(item.occupation_name) ? item.occupation_name : item.occupation_id;
    }
  },
};
</script>
