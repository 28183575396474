<template>
  <div class="d-flex align-items-center justify-content-center p-4" v-if="!isLoading">
    <b-card class="col-md-6 col-lg-6 col-xl-4 px-0" variant="primary">
      <form @submit.prevent autocomplete="off">
        <validation-observer ref="curriculumStep4Rules">
          <div class="d-flex flex-column gap-4">
            <vacancy-header v-if="internalVacancy" :item="internalVacancy" />
            <div>
              <h3 class="text-xl font-weight-semibold">
                <span>{{ $t("registration.lets_create_your_password") }}</span>
              </h3>
              <p class="text-subdued">
                {{ $t("registration.you_will_need_it_to_access_your_account") }}
              </p>
            </div>

            <div>
              <h4 class="pb-4">
                {{ $t("curriculum.sections.personal.login") }}
              </h4>
              <div>
                <form-field-password
                  name="curriculum.sensitive.password"
                  v-model="curriculum.sensitive.password"
                  rules="required|password-username-checker|password-weakness-checker|max:64"
                  autocomplete="off"
                  untracked
                  immediate
                  />

                <form-field-password
                  name="curriculum.sensitive.password_confirmation"
                  v-model="curriculum.sensitive.password_confirmation"
                  rules="required|confirmed:curriculum.sensitive.password"
                  untracked
                  autocomplete="off"
                />
              </div>

              <password-strength-checker
                :password="curriculum.sensitive.password"
                :password_confirmation="curriculum.sensitive.password_confirmation"
              />
            </div>

            <b-button variant="primary" block pill @click="submitForm()">
              {{ $t("curriculum.sections.personal.bnt_ready") }}
            </b-button>
          </div>
        </validation-observer>
      </form>
    </b-card>
  </div>
</template>

<script>
import companyMixin from "@state/company";
import { useAuth } from "@state/auth/auth";
import { useDomain } from "@state/domain/domain";
import * as FormComponents from "@components/forms";
import { useCompanyForm } from "@state/company/form";
import { useCurriculum } from "@state/user/curriculum";
import { useVacancies } from "@state/vacancy/vacancies";
import { useChangeable } from "@state/behaviours/changeable";
import VacancyHeader from "@blocks/vacancy/VacancyHeader.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PasswordStrengthChecker from "@components/user/PasswordStrengthChecker.vue";

export default {
  mixins: [companyMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    VacancyHeader,
    ...FormComponents,
    PasswordStrengthChecker,
  },
  props: {
    vacancy_id: Number | String,
    change_password: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      domain: null,
      forms: null,
      curriculum: null,
      changeable: null,
      vacancies: null,
      isLoading: true,
      sections: {},
      internalVacancy: null,
    };
  },
  created() {
    this.domain = useDomain();
    this.forms = useCompanyForm();
    this.curriculum = useCurriculum();
    this.changeable = useChangeable();
    this.vacancies = useVacancies();
    this.authentication = useAuth();

    this.isLoading = true;
    this.$root.loading = true;

    if (this.vacancy_id) {
      this.vacancies.read(this.vacancy_id).then(({ data }) => {
        this.internalVacancy = data;
      });
    }

    this.forms.fields("express", true).then((fields) => {
      for (let i in this.forms.express.sections) {
        this.$set(this.sections, i, this.forms.express.sections[i]);
      }
    }).finally(() => {
      this.isLoading = false;
      this.$root.loading = false;
    })
  },

  destroyed() {
    this.curriculum.resetState();
  },

  computed: {
    firstName() {
      return this.curriculum.personal.name.split(" ")[0] || "";
    },
  },

  methods: {

    submitForm() {
      this.changePassword()
    },
    changePassword() {
      this.$refs.curriculumStep4Rules.validate().then((success) => {
        if (!success) {
          this.inputFocus();
          this.$root.loading = false;
          return;
        }

        this.isSaving = true;
        this.isLoading = true;
        this.$root.loading = true;

        const password_data = {
          is_sj: this.company.config.sj ?? false,
          password: this.curriculum.sensitive.password,
          password_confirmation: this.curriculum.sensitive.password_confirmation,
        };

        this.authentication.changePassword(password_data)
          .then((response) => {

            // Clear local data after save
            this.changeable.clearLocalStorage();
            this.changeable.cleanUpAfterSave();

            // ENROLL HERE!!
            if (this.vacancy_id) {
              this.$router.push({ name: "vacancy-enroll", params: { vacancy_id: this.vacancy_id }});

            // REGISTER HERE
            } else {
              this.$router.push({ name: "profile", query: { showNps: false }});
            }
          })
          .catch((error) => {
            // Error notifications provided by SSO
          })
          .finally(() => {
            this.isSaving = false;
            this.isLoading = false;
            this.$root.loading = false;
          })
      });
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        el.scrollIntoView();
        return;
      });
    },
  },
};
</script>
