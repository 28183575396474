<template>
  <div
    v-if="company && company.config != null"
    class="page"
    :class="{
      'page-content-sidebar': theme.customize,
      'sidebar-enabled': hasSidebar,
    }"
  >
    <customize-sidebar
      id="customize-sidebar"
      v-if="!isMobile() && theme.customize"
      v-model="theme.customize"
      @hidden="theme.customize = false"
      @shown="theme.customize = true"
    />

    <b-sidebar
      v-if="hasSidebar && !theme.slim"
      class="sidebar-menu d-none d-lg-block"
      sidebar-class="px-6 py-4"
      header-class="mb-16"
      bg-variant="white"
      left
      no-close-on-route-change
      no-close-on-esc
      no-header-close
      visible
      width="300"
      shadow
    >
      <template #header>
        <router-link :to="{ name: 'home' }" class="text-center">
          <img :src="companyLogo" alt="" style="max-height: 32px" />
        </router-link>
      </template>

      <template #footer>
        <img :src="powered_by_sj" alt="" height="15" width="146" />
      </template>

      <div class="d-flex flex-column gap-3">
        <router-link
          :to="{ name: 'profile' }"
          v-slot="{ navigate, href, isActive }"
        >
          <a
            :href="href"
            @click="navigate"
            class="py-3 px-4 text-default d-flex align-items-center gap-4"
            :class="{ active: isActive }"
          >
            <HomeIcon class="menu-icon" />

            <span>{{ $t("common.my_painel") }}</span>
          </a>
        </router-link>
        <router-link
          :to="{ name: 'profile-applications' }"
          v-slot="{ navigate, href, isActive }"
        >
          <a
            :href="href"
            @click="navigate"
            class="py-3 px-4 text-default d-flex align-items-center gap-4"
            :class="{ active: isActive }"
          >
            <BriefcaseIcon class="menu-icon" />
            <span>{{ $t("application.myApplications") }}</span>
          </a>
        </router-link>

        <router-link
          :to="{ name: 'profile-edit' }"
          v-slot="{ navigate, href, isActive }"
        >
          <a
            :href="href"
            @click="navigate"
            class="py-3 px-4 text-default d-flex align-items-center gap-4"
            :class="{ active: isActive }"
          >
            <DocumentTextIcon class="menu-icon" />
            <span>{{ $t("common.curriculum") }}</span>
          </a>
        </router-link>

        <router-link
          :to="{ path: 'complementary-data' }"
          v-if="!company.config.sj && show_complementary_data_form"
          v-slot="{ navigate, href, isActive }"
        >
          <a
            :href="href"
            @click="navigate"
            class="py-3 px-4 text-default d-flex align-items-center gap-4"
            :class="{ active: isActive }"
          >
            <ViewGridAddIcon class="menu-icon" />
            <span>{{ $t("common.admission_data") }}</span>
          </a>
        </router-link>

        <router-link
          :to="{ name: 'profile-messages' }"
          v-slot="{ navigate, href, isActive }"
          v-if="$auth.check() && !company.config.sj"
        >
          <a
            :href="href"
            @click="navigate"
            class="py-3 px-4 text-default d-flex align-items-center gap-4"
            :class="{ active: isActive }"
          >
            <ChatBubbleBottomCenterTextIcon class="menu-icon" />

            <span>{{ $t("common.messages") }}</span>
          </a>
        </router-link>
        <router-link
          :to="{ name: 'profile-configurations' }"
          v-slot="{ navigate, href, isActive }"
        >
          <a
            :href="href"
            @click="navigate"
            class="py-3 px-4 text-default d-flex align-items-center gap-4"
            :class="{ active: isActive }"
          >
            <Cog6ToothIcon class="menu-icon" />

            <span>{{ $t("common.account") }}</span>
          </a>
        </router-link>
      </div>
    </b-sidebar>

    <div class="page-content" id="page-content">
      <div class="navbar-container">
        <b-navbar
          toggleable="lg"
          :class="{ 'has-sidebar': hasSidebar, 'mb-16': isNormalSquareLogo }"
          v-if="!theme.slim"
          class="container-xl navbar-page px-4 py-2"
        >
          <b-navbar-brand href="/" :class="{ 'd-lg-none': hasSidebar }">
            <img
              class="logo-page"
              :src="companyLogo"
              alt=""
              v-if="showLogo && company.config.logo_position === 'default'"
            />

            <b-avatar
              rounded="sm"
              variant="neutral-subdued square-logo-avatar"
              v-if="
                showLogo && company.config.logo_position === 'normal-square'
              "
            >
              <template #default="{}">
                <img :src="companyLogo" alt="" />
              </template>
            </b-avatar>
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse" class="border-none" v-if="showHeaderMenuItems">
            <letter-avatar
              class="d-visible d-lg-none"
              v-if="$auth.check()"
              :avatar-url="activeUserInfo.avatar"
              :name="activeUserInfo.name"
            />
            <ViewListIcon v-else />
          </b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav v-if="showHeaderMenuItems">
            <b-navbar-nav
              class="ml-auto align-items-center gap-6"
              v-if="!company.config.is_redirector"
            >
              <b-nav-item
                :to="{ name: 'profile' }"
                v-if="$auth.check()"
                class="font-weight-bold"
              >
                {{ $t("common.my_painel") }}
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'vacancy-search' }"
                class="font-weight-bold"
              >
                {{ $t("common.search") }}
                <span class="text-lowercase">{{ $t("common.jobs") }}</span>
              </b-nav-item>

              <b-nav-item
                v-if="hasPageForCompanies"
                :to="{ name: 'companies' }"
                class="font-weight-bold"
              >
                {{ $t("common.for_companies") }}
              </b-nav-item>

              <!-- <b-nav-dropdown
                class="d-none d-lg-block"
                v-if="$auth.check()"
                no-caret
                right
              >
                <template #button-content>
                  <BellIcon /> <span class="sr-only">Notificações</span>
                </template>

                <b-dropdown-item><span class="mx-4">Notificação 1</span></b-dropdown-item>
              </b-nav-dropdown>     -->

              <!-- <b-tooltip target="tooltip-target-1" triggers="hover">
                Visibilidade do chat
              </b-tooltip>

              <b-nav-dropdown
                class="d-none d-lg-block"
                v-if="$auth.check()"
                no-caret
                right
                size="sm"
              >
                <template #button-content>
                  <div id="tooltip-target-1">
                  <CheckCircleIcon class="text-success" /> Online
                </div>
                </template>

                <b-dropdown-item><CheckCircleIcon class="text-success" /> Online</b-dropdown-item>
                <b-dropdown-item><ExclamationCircleIcon class="text-warning" /> Ausente</b-dropdown-item>
                <b-dropdown-item><XCircleIcon class="text-muted" /> Offline</b-dropdown-item>
              </b-nav-dropdown>                -->

              <b-nav-item
                :to="{ name: 'create-account' }"
                class="font-weight-bold"
                v-if="
                  !$auth.check() &&
                  !company.config.is_internal &&
                  !company.config.is_agreggator
                "
              >
                {{ $t("common.create_profile") }}
              </b-nav-item>

              <b-nav-item v-if="!$auth.check() && !company.config.is_agreggator">
                <b-button :to="{ name: 'login' }" variant="outline-primary" pill size="sm">
                  {{ $t("common.join") }}
                </b-button>
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'profile-applications' }"
                v-if="$auth.check()"
                class="d-lg-none"
              >
                {{ $t("application.myApplications") }}
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'profile-messages' }"
                v-if="$auth.check() && !company.config.sj"
                class="d-lg-none"
              >
                {{ $t("common.messages") }}
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'profile-configurations' }"
                v-if="$auth.check()"
                class="d-lg-none"
              >
                {{ $t("common.account") }}
              </b-nav-item>

              <b-nav-item
                :to="{ name: 'profile-edit' }"
                v-if="$auth.check()"
                class="d-lg-none"
              >
                {{ $t("common.curriculum") }}
              </b-nav-item>

              <b-nav-item
                :to="{ path: 'complementary-data' }"
                v-if="
                  $auth.check() &&
                  !company.config.sj &&
                  show_complementary_data_form
                "
                class="d-lg-none"
              >
                {{ $t("common.admission_data") }}
              </b-nav-item>

              <b-nav-item
                @click="logout"
                v-if="$auth.check()"
                class="d-lg-none"
              >
                {{ $t("common.logout") }}
              </b-nav-item>

              <b-nav-dropdown
                class="d-none d-lg-block"
                v-if="$auth.check()"
                no-caret
              >
                <template #button-content>
                  <letter-avatar
                    :avatar-url="activeUserInfo.avatar"
                    :name="activeUserInfo.name"
                  />
                </template>

                <b-dropdown-item :to="{ name: 'profile-edit' }">
                  {{ $t("common.curriculum") }}
                </b-dropdown-item>

                <div class="dropdown-divider"></div>

                <b-dropdown-item @click="logout">
                  {{ $t("common.logout") }}
                </b-dropdown-item>
              </b-nav-dropdown>

              <b-nav-item v-if="company.config.sj">
                <i18n />
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
      <div class="page-component" id="page-component">
        <router-view />
        <PortalRodape :config="company.config" v-if="!hasSidebar" />
      </div>
    </div>
    <CookieConsent />

    <ContactWidget />
  </div>
</template>

<script>
import * as _ from "lodash-es";
import { BAvatar } from "bootstrap-vue";
import companyMixin from "@state/company";
import i18n from "@components/user/I18n.vue";
import { useTheme } from "@state/system/theme";
import { useAuth } from "@state/auth/auth";
import CustomizeSidebar from "./CustomizeSidebar.vue";
import PortalRodape from "./sections/PortalRodape.vue";
import CookieConsent from "@/plugins/CookieConsent";
import LetterAvatar from "@components/avatar/LetterAvatar.vue";
import ContactWidget from "@components/help/ContactWidget.vue";
import {
  ViewListIcon,
  BriefcaseIcon,
  DocumentTextIcon,
  ViewGridAddIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  OfficeBuildingIcon,
} from "@vue-hero-icons/solid";
import { BellIcon } from "@vue-hero-icons/outline";
import powered_by_sj from "@assets/images/logo/powered_by_sj.png";
import { useConfiguration } from "@state/user/configuration";
import { useCurriculum } from "@state/user/curriculum";
//import { useWebsocket } from "@state/system/websocket";

import {
  HomeIcon,
  ChatBubbleBottomCenterTextIcon,
  Cog6ToothIcon,
} from "@components/icons";

export default {
  mixins: [companyMixin],
  setup() {
    const theme = useTheme();
    //const websocket = useWebsocket();
    const configuration = useConfiguration();
    const curriculum = useCurriculum();
    const authentication = useAuth();

    return {
      theme,
      //websocket,
      powered_by_sj,
      isMobile: theme.isMobile,
      configuration,
      curriculum,
      authentication,
    };
  },
  components: {
    BAvatar,
    CustomizeSidebar,
    PortalRodape,
    i18n,
    LetterAvatar,
    CookieConsent,
    HomeIcon,
    BriefcaseIcon,
    DocumentTextIcon,
    ViewGridAddIcon,
    ChatBubbleBottomCenterTextIcon,
    Cog6ToothIcon,
    ViewListIcon,
    ContactWidget,
    BellIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    XCircleIcon,
    OfficeBuildingIcon,
  },
  data() {
    return {
      config: null,
      show_complementary_data_form: false,
    };
  },
  created() {
    this.checkComplementaryForm();
  },
  computed: {
    activeUserInfo() {
      return this.$auth.user();
    },
    hasSidebar() {
      return !_.has(this.$route.meta, "sidebar") || this.$route.meta.sidebar;
    },
    showLogo() {
      return (
        ["default", "normal-square"].indexOf(
          this.company?.config?.logo_position
        ) !== -1
      );
    },
    companyLogo() {
      if (this.company.config.logo_position === "default") {
        return this.company?.config?.menu?.logo;
      }

      return this.company?.config?.menu?.square_logo;
    },
    showHeaderMenuItems() {

      /**
       * Prop to hide header menu items
       */
      if (_.has(this.$route.meta, "header_menu") && this.$route.meta.header_menu === false) {
        return false;
      }

      return true;
    },
    hasPageForCompanies() {
      return _.get(this.company.config.pages, 'companies', false);
    },
    isNormalSquareLogo() {
      if (
        this.showLogo &&
        this.company.config.logo_position === "normal-square" &&
        !['home', 'companies'].includes(this.$route.name)
      ) {
        return true;
      }

      return false;
    }
  },
  methods: {
    logout() {
        console.info('logout painel')
        this.authentication.logout().finally(() => {
            this.$auth.logout().then(() => {
                //
            }).finally(() => {
                console.info('logout painel2')
                this.curriculum.resetState();
                localStorage.removeItem("curriculum_express");
                localStorage.removeItem("bank_account_selective_process");
                this.$root.loading = false;
                this.$router.push({ name: "home" })
            });
        })
    },

    checkComplementaryForm() {
      if (this.$auth.check() && !this.company.config.sj) {
        this.configuration.checkComplementaryHiringForm().then((response) => {
          this.show_complementary_data_form = response.data.can;
        });
      }
    },
  },
  watch: {
    $route(to, from) {
      const authRequired = to.matched.some((x) => x.meta.auth);
      if (authRequired) {
        this.checkComplementaryForm();
      }
    },
  },
};
</script>
