<template>
  <div class="d-flex align-items-center justify-content-center p-4">
    <b-card class="col-md-6 col-lg-6 col-xl-4 px-0 w-100 h-100">
      <div v-if="change_password">
        <h5 class="text-success">{{ $t("user.forgot.email_sent") }}</h5>
        <p>{{ $t("user.forgot.email_instructions", { email }) }}</p>
        <p>{{ $t("user.forgot.email_spam_instructions") }}</p>

        <b-button
          class="btn-block border"
          variant="link"
          @click="$router.push({ name: 'forgot-account' })"
          pill
          size="sm"
        >
          {{ $t("user.forgot.no_longer_email_access") }}
        </b-button>
      </div>
      <form @submit.prevent autocomplete="off" v-else>
        <validation-observer ref="curriculumStep1Rules">
          <div>
            <vacancy-header
              v-if="internalVacancy"
              :item="internalVacancy"
              class="mb-6"
            />
            <h3 class="text-xl font-weight-semibold">
              <span v-if="account_exists">
                {{ $t("registration.account_not_found") }}
              </span>
              <span v-if="vacancy_id">
                {{ $t("registration.congratulations_text") }}
              </span>
              <span v-else>{{ $t("common.lets_begin") }}</span>
            </h3>
            <p v-if="vacancy_id" class="text-subdued">
              {{ $t("registration.candidacy_start") }}
            </p>
            <p v-else class="text-subdued">
              {{ $t("common.lets_begin_subtitle") }}
            </p>

            <form-field-input
              name="curriculum.personal.name"
              v-model="curriculum.personal.name"
              rules="required|full-name"
              label-class="font-weight-semibold"
            />

            <form-field-input
              name="curriculum.personal.cpf"
              v-model="curriculum.personal.cpf"
              mask="###.###.###-##"
              label-class="font-weight-semibold"
              inputmode="decimal"
              :rules="{'cpf': true, 'required': !curriculum.personal.is_foreigner}"
              v-show="!curriculum.personal.is_foreigner"
            />

            <form-field-input
              name="curriculum.personal.primary_email"
              :rules="{'email': true, 'required': curriculum.personal.is_foreigner}"
              v-model="curriculum.personal.primary_email"
              label-class="font-weight-semibold"
              inputmode="email"
              type="email"
              v-show="curriculum.personal.is_foreigner"
            />

            <form-field-switch
              name="curriculum.personal.is_foreigner"
              v-model="curriculum.personal.is_foreigner"
              label-class="font-weight-semibold"
              autocomplete="off"
              v-if="company.config.flags.foreigner_registration"
            />

            <div class="d-flex flex-column gap-6 text-center">
              <p class="text-subdued">
                {{ $t("registration.agree_with") }}

                <span v-if="!isSJ && hasCustomPrivacyPolicy">
                  {{ $t("registration.as") }}
                  <a
                    :href="company.config.privacy_policy"
                    target="_blank"
                  >
                    {{ $t("registration.specific_policies") }}
                  </a>
                  <span>
                    {{ $t("registration.from_company") }}
                    <span class="font-weight-bold">
                      {{ company.config.company_name }},
                    </span>
                  </span>
                </span>

                {{ $t("registration.os") }}
                <a href="https://selecty.com.br/termos-de-uso/" target="_blank">
                  {{ $t("registration.terms_and_conditions") }}
                </a>
                {{ $t("registration.and_the") }}
                <a
                  href="https://selecty.com.br/politica-privacidade"
                  target="_blank"
                >
                  {{ $t("registration.privacy_policy") }}
                </a>
                {{ $t("registration.from_selecty_platform") }}
              </p>

              <b-button
                variant="primary"
                type="submit"
                data-cy="create-account-submit"
                @click="nextStep()"
                pill
                block
              >
                {{ $t("registration.start") }}
              </b-button>

              <p class="text-subdued">
                {{ $t("registration.hasAccount") }}

                <router-link
                  :to="{
                    name: 'login',
                    params: { vacancy_id: this.vacancy_id },
                  }"
                  >{{ $t("registration.login") }}</router-link
                >
              </p>

              <div class="divider">
                <hr class="w-100" />
                <span
                  class="text-subdued px-2 d-inline-block relative bg-white"
                  style="top: -29px"
                  >{{ $t("common.or") }}</span
                >
              </div>
            </div>

            <b-button
              v-if="isAuthAllowed('sj') && !isSJ && provider != 'sj'"
              variant="dt-secondary"
              pill
              block
              href="/login/sj"
            >
              <span>
                <img
                  width="20px"
                  :src="
                    imagesList['/resources/assets/images/social/sj_small.png']
                  "
                  alt="SelectyJobs"
                />
              </span>
              <span> &nbsp; {{ $t("common.join_with_sj") }}</span>
            </b-button>

            <b-button
              v-if="
                isAuthAllowed('ad') &&
                company.config.is_internal &&
                !isSJ &&
                provider != 'sj'
              "
              variant="dt-secondary"
              pill
              block
              :href="'/ad/redirect'"
            >
              <span>
                <img
                  width="20px"
                  :src="imagesList['/resources/assets/images/social/ad.svg']"
                  alt="SelectyJobs"
                />
              </span>
              <span> &nbsp; {{ $t("common.join_with_ad") }}</span>
            </b-button>

            <!-- <b-button

            variant="dt-secondary"
            pill
            block
            :href="ssoUrl + '/azure/redirect'"
          >
            <span>
              <img
                width="20px"
                :src="imagesList['/resources/assets/images/social/azure.png']"
              />
            </span>
            <span> &nbsp; {{ $t("common.join_with_azure") }}</span>
          </b-button>           -->
            <b-button
              v-if="isAuthAllowed('linkedin') && provider != 'sj'"
              variant="dt-secondary"
              pill
              block
              href="/linkedin/redirect"
            >
              <span>
                <img
                  width="20px"
                  :src="imagesList['/resources/assets/images/social/lk.png']"
                />
              </span>
              <span> &nbsp; {{ $t("common.join_with_linkedin") }}</span>
            </b-button>

            <b-button
              v-if="isAuthAllowed('facebook') && provider != 'sj'"
              variant="dt-secondary"
              pill
              block
              href="/facebook/redirect"
            >
              <span>
                <img
                  width="20px"
                  :src="imagesList['/resources/assets/images/social/fb.png']"
                  alt="Facebook"
                />
              </span>
              <span> &nbsp; {{ $t("common.join_with_facebook") }}</span>
            </b-button>

            <b-button
              v-if="isAuthAllowed('google') && provider != 'sj'"
              variant="dt-secondary"
              pill
              block
              href="/google/redirect"
            >
              <span>
                <img
                  width="20px"
                  :src="
                    imagesList['/resources/assets/images/social/google.png']
                  "
                  alt="Google"
                />
              </span>
              <span> &nbsp; {{ $t("common.join_with_google") }}</span>
            </b-button>
          </div>
        </validation-observer>
      </form>
    </b-card>
    <b-modal
      id="modal-terms-conditions"
      size="lg"
      ok-only
      scrollable
      no-close-on-backdrop
      hide-header-close
    >
      <div v-html="terms_conditions"></div>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" pill @click="hide">{{ $t("common.close") }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as _ from "lodash-es";
import { VBModal } from "bootstrap-vue";
import companyMixin from "@state/company";
import { notify } from "@/plugins/notification";
import * as FormComponents from "@components/forms";
import { useCurriculum } from "@state/user/curriculum";
import { useVacancies } from "@state/vacancy/vacancies";
import { useConfiguration } from "@state/user/configuration";
import { useChangeable } from "@state/behaviours/changeable";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import images from "@mixins/images";

import VacancyHeader from "@blocks/vacancy/VacancyHeader.vue";

export default {
  mixins: [companyMixin, images],
  components: {
    ValidationObserver,
    ValidationProvider,
    ...FormComponents,
    VacancyHeader,
  },
  props: {
    provider: {
      type: String,
      required: false,
      default: "candidate",
      validator: (prop) => ["candidate", "sj"].indexOf(prop) >= 0,
    },
  },
  setup() {
    const curriculum = useCurriculum();
    const vacancies = useVacancies();
    const configuration = useConfiguration();
    const changeable = useChangeable();
    const images = import.meta.glob("/resources/assets/images/social/*.png", {
      import: "default",
      eager: true,
    });

    return {
      curriculum,
      vacancies,
      configuration,
      changeable,
      images,
    };
  },
  computed: {
    authTypes() {
      return this.isSJ
        ? ["default", "linkedin", "facebook", "google"]
        : this.company.config.flags["auth.external.allowed"];
    },
    isSJ() {
      return this.company.config.sj;
    },
    ssoUrl() {
      return this.company.config.sso_url;
    },
    hasCustomPrivacyPolicy() {
      return !_.isEmpty(this.company.config.privacy_policy)
    }
  },
  data() {
    return {
      terms_conditions: "",
      email_sent_to: "",
      internalVacancy: null,
      change_password: false,
      provider: "",
    };
  },
  props: {
    vacancy_id: Number | String,
    //vacancy: Object,
    account_exists: Boolean,
  },
  created() {

    // Clearing old state
    this.curriculum.resetState();
    this.changeable.clearLocalStorage();
    this.changeable.cleanUpAfterSave();

    if (this.vacancy_id) {
      this.vacancies.read(this.vacancy_id).then(({ data }) => {
        this.internalVacancy = data;
      });
    }

    this.stateSubscribe = this.changeable.$subscribe((mutation, state) => {
      const poll_changes = this.changeable.poolChanges("curriculum");
      this.changeCounter = poll_changes.counter;

      if (poll_changes.counter >= 1) {
        // SAVE PARTIAL CURRICULUM
        this.isSaving = true;
        this.changeable.localPersist("changeable-express-form");
        this.changeCounter = 0;
      }
    });
  },
  destroyed() {
    this.stateSubscribe();
  },
  methods: {
    onlyAuthAllowed(type) {
      return this.isAuthAllowed(type) && this.authTypes.length == 1;
    },
    isAuthAllowed(type) {
      return this.authTypes.includes(type);
    },

    nextStep() {
      this.$refs.curriculumStep1Rules.validate().then((success) => {
        this.$root.loading = true;

        if (success) {
          if (this.curriculum.personal.is_foreigner) {
            this.$router.push({
              name: "register2",
              params: { vacancy_id: this.vacancy_id },
            });

            this.$root.loading = false;

            return;
          } else {
            if (_.isEmpty(this.curriculum.personal.cpf)) {
              this.$refs.curriculumStep1Rules.setErrors({
                "curriculum.personal.cpf": ["O campo CPF é obrigatório"],
              });
              this.$root.loading = false;
              return;
            }
          }

          this.curriculum
            .checkRegistration({
              cpf: this.curriculum.personal.cpf,
            })
            .then((response) => {
              let route = { name: "login" };

              if (!response.data.success) {
                if (this.isSJ && response.data.change_password) {
                  this.change_password = true;
                  this.$root.loading = false;
                  this.email_sent_to = response.data.email;
                  return;
                } else {
                  notify({
                    title: "$t:errors.title.danger",
                    message: "$t:errors.registration.account-existent",
                    variant: "danger",
                  });

                  route = {
                    name: "login",
                    params: { vacancy_id: this.vacancy_id },
                  };
                }
              } else {
                route = {
                  name: "register2",
                  params: { vacancy_id: this.vacancy_id },
                };
              }

              if (!response.data.internal) {
                this.$router.push(route);
              }

              this.$root.loading = false;
            })
            .catch((error) => {
              notify({
                title: "$t:errors.title.danger",
                message: "$t:errors.registration.message",
                variant: "danger",
              });
              this.$root.loading = false;
            });
        } else {
          this.inputFocus();
          this.$root.loading = false;
          return;
        }
      });
    },
    showTermsAndConditions(e) {
      this.configuration.getTermsAndConditions().then((response) => {
        this.terms_conditions = response.data;

        this.$bvModal.show("modal-terms-conditions");
      });
      e.preventDefault();
    },
    showPrivacyPolicy(e) {
      this.configuration.getPrivacyPolicy().then((response) => {
        this.terms_conditions = response.data;

        this.$bvModal.show("modal-terms-conditions");
      });
      e.preventDefault();
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        el.scrollIntoView();
        return;
      });
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
