<template>
  <div
    id="portal-banner-logo"
    data-scroll-to="portal-banner-logo"
    :style="ignoreSpacingStyle"
  >
    <h4 class="mb-4" v-if="title">{{ title }}</h4>

    <div v-for="(value, index) in images" :key="index">
      <a :href="value.redirect_url ? value.redirect_url : null" target="_blank">
        <img
          class="img-fluid"
          :src="getUrlSrc(value.src)"
          :alt="alt"
          style="height: 100%; width: 100%; object-fit: contain"
          @error="renderPlaceholder($event)"
        />
      </a>
    </div>
  </div>
</template>
  
  <script>
import { useTheme } from "@state/system/theme";
import images from "@mixins/images";
import companyMixin from "@state/company";
import bannerPlaceholder from "./assets/banner_moisaco_example.png";

export default {
  mixins: [companyMixin, images],
  props: {
    images: {
      type: Array,
      required: false,
    },
    title: {
      type: String,
      default: "",
    },
    alt: {
      type: String,
      default: "",
    },
    ignore_top_gap: {
      type: Boolean,
      default: false,
    },
    ignore_bottom_gap: {
      type: Boolean,
      default: false,
    },
    background_color: {
      type: String,
      default: "",
    },
  },
  setup() {
    const theme = useTheme();
    return {
      theme,
      isMobile: theme.isMobile,
    };
  },
  computed: {
    isSJ() {
      return this.company.config.sj;
    },
    ignoreSpacingStyle() {
      if (!this.ignore_top_gap && !this.ignore_bottom_gap) {
        return;
      }

      const size = this.isMobile() ? "2.5rem" : "4rem";

      let styles = {
        padding: "0 !important",
      };

      if (this.background_color) {
        styles["background-color"] = this.background_color;
      }

      if (this.ignore_top_gap) {
        styles["margin-top"] = `-${size}`;
      }

      if (this.ignore_bottom_gap) {
        styles["margin-bottom"] = `-${size}`;
      }

      return styles;
    },
  },
  data() {
    return {
      placeholderImage: bannerPlaceholder,
    };
  },
  methods: {
    getUrlSrc(src) {
      if (this.isSJ) {
        return this.imagesList[src];
      } else {
        return src;
      }
    },
    renderPlaceholder(ev) {
      ev.target.src = this.placeholderImage;
    },
    ignoreGap() {
      const size = this.isMobile() ? "2.5rem" : "4rem";
      return this.ignore_gap ? "" : size;
    },
  },
};
</script>