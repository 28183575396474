<template>
  <div class="d-flex align-items-center justify-content-center p-4" v-if="!isLoading">
    <b-card class="col-md-6 col-lg-6 col-xl-4 px-0" variant="primary">
      <form @submit.prevent autocomplete="off">
        <validation-observer ref="curriculumStep3Rules">
          <div class="d-flex flex-column gap-4">
            <vacancy-header v-if="internalVacancy" :item="internalVacancy" />
            <div>
              <h3 class="text-xl font-weight-semibold">
                <span>{{ $t("registration.finish_your_account_creation") }}</span>
              </h3>
              <p class="text-subdued">
                {{ $t("registration.you_need_your_account_to_check") }}
              </p>
            </div>
            <div>
              <form-set-generic
                v-model="curriculum.extra"
                form="express"
                section="extra"
                :fields="sections.personal.extra.fields"
                :title="$t('curriculum.sections.personal.extra_by_company', { company: company.config.company_name })"
              />
            </div>
            <b-button variant="primary" block pill @click="submitForm()">
              {{ $t("curriculum.sections.personal.bnt_ready") }}
            </b-button>
          </div>
        </validation-observer>
      </form>
    </b-card>
  </div>
</template>

<script>
import companyMixin from "@state/company";
import { useAuth } from "@state/auth/auth";
import { useDomain } from "@state/domain/domain";
import * as FormComponents from "@components/forms";
import { useCompanyForm } from "@state/company/form";
import { useCurriculum } from "@state/user/curriculum";
import { useVacancies } from "@state/vacancy/vacancies";
import { useChangeable } from "@state/behaviours/changeable";
import VacancyHeader from "@blocks/vacancy/VacancyHeader.vue";
import FormSetGeneric from "@components/forms/set/Generic.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  mixins: [companyMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    VacancyHeader,
    FormSetGeneric,
    ...FormComponents,
  },
  props: {
    vacancy_id: Number | String,
  },
  data() {
    return {
      domain: null,
      forms: null,
      curriculum: null,
      changeable: null,
      vacancies: null,
      isLoading: true,
      sections: {},
      internalVacancy: null,
    };
  },
  created() {
    this.domain = useDomain();
    this.forms = useCompanyForm();
    this.curriculum = useCurriculum();
    this.changeable = useChangeable();
    this.vacancies = useVacancies();
    this.authentication = useAuth();

    this.isLoading = true;
    this.$root.loading = true;

    if (this.vacancy_id) {
      this.vacancies.read(this.vacancy_id).then(({ data }) => {
        this.internalVacancy = data;
      });
    }

    this.forms.fields("express", true).then((fields) => {
      for (let i in this.forms.express.sections) {
        this.$set(this.sections, i, this.forms.express.sections[i]);
      }
    }).finally(() => {
      this.isLoading = false;
      this.$root.loading = false;
    })
  },

  methods: {
    submitForm() {
      this.$refs.curriculumStep3Rules.validate().then((success) => {
        if (!success) {
          this.inputFocus();
          this.isSaving = false;
          return;
        }

        this.isSaving = true;

        // Save extra fields
        const poll_changes = this.changeable.poolChanges("express");
        this.curriculum.persist(poll_changes.changes).then((data) => {
          if (data.data.success) {

            // Clear local data after save
            this.changeable.clearLocalStorage();
            this.changeable.cleanUpAfterSave();

            // Redirect to the next step
            this.$router.push({ name: "register4", params: { vacancy_id: this.vacancy_id }});
          }
        })
        .catch(error => {
          notify({ title: "$t:errors.title.danger", message: "$t:common.data_save_error", variant: "danger" });
        })
        .finally(() => {
          this.isSaving = false;
          this.isLoading = false;
          this.$root.loading = false;
        })
      });
    },
    inputFocus() {
      this.$nextTick(() => {
        const el = this.$el.querySelector(".is-invalid");
        el.scrollIntoView();
        return;
      });
    },
  },
};
</script>
