<template>
  <validation-provider
    ref="validator"
    #default="{ errors, required }"
    :name="internalLabel"
    :vid="internalVid"
    :rules="rules"
    slim
  >
    <b-form-group
      :id="fieldsetId"
      :name="internalVid"
      :description="internalDescription"
      :label="internalLabel"
      :label-for="inputId"
      :state="errors.length == 0"
      class="search-bar-item"
    >
      <input
        :data-cy="internalTestId + '-proxy'"
        type="hidden"
        v-model="internalValue"
      />

      <template #label>
        <slot name="label" :inputId="inputId" :label="internalLabel">
          <label
            :id="fieldsetId + '__BV_label_'"
            :for="inputId"
            class="d-block"
            v-if="internalLabel"
          >
            {{ internalLabel }}
            <span v-if="required" class="text-danger">*</span>
          </label>
        </slot>
      </template>

      <v-select
        ref="select"
        :id="inputId"
        :inputId="inputId"
        :data-cy="internalTestId"
        v-model="internalValue"
        :class="{ 'is-invalid': errors.length > 0 }"
        :reduce="reduce"
        :placeholder="internalPlaceholder"
        v-on="$listeners"
        @input="dispatchEvent('change', internalValue)"
        v-bind="$attrs"
        autocomplete="off"
        :options="filteredOptions"
        :filterable="false"
        @search="searchOptions"
      >
        <template #no-options="{ search, searching, loading }">
          <slot
            name="no-options"
            v-bind:search="search"
            v-bind:searching="searching"
            v-bind:loading="loading"
          >
            {{ $t("common.no-results-found") }}
          </slot>
        </template>

        <template
          #selected-option-container="{ option, deselect, multiple, disabled }"
        >
          <div
            class="vs__selected d-block"
            :class="{ 'max-w-30 text-truncate': multiple }"
          >
            <span
              size="sm"
              @click="deselect(option)"
              v-if="multiple"
              class="cursor-pointer"
            >
              <XIcon size="0.9x" />
            </span>
            <span class="overflow-x-hidden">
              {{ option.label }}
            </span>
          </div>
        </template>
      </v-select>

      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import * as _ from "lodash-es";
import { useDomain } from "@state/domain/domain";
import FormFieldBase from "../mixins/Base";
import { ValidationProvider } from "vee-validate";
import { XIcon } from "@vue-hero-icons/outline";

/**
 * Componente que encapsula o vue-select
 * @see https://vue-select.org/
 */
export default {
  name: "form-field-select-search",
  mixins: [FormFieldBase],
  components: {
    ValidationProvider,
    XIcon,
  },

  props: {
    reduce: {
      type: Function,
      default(option) {
        return option.value || option;
      },
    },
    apiResource: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      internalValue: this.value,
      initialStateOptions: [],
      filteredOptions: [],
    };
  },
  created() {
    this.domain = useDomain();
    this.fetchInitialOptions();
  },
  methods: {
    clearField() {
      this.internalValue = -1;
    },
    selectValue(value) {
      this.$nextTick(() => {
        this.$refs.select.select(this.internalValue);
      });
    },

    fetchInitialOptions() {
      let params = {
        limit: 20,
      };

      if (_.isNumber(this.internalValue) && !_.isNaN(this.internalValue)) {
        params.filter_by = "value";
        params.search = this.internalValue;
      }

      let use_debounce = false;

      this.domain
        .filterApiResource(this.apiResource, params, use_debounce)
        .then((response) => {
          this.initialStateOptions = response.data;
          this.filteredOptions = this.initialStateOptions;
          this.appendToDomain();
        })
        .catch((error) => {})
        .finally(() => {});
    },

    searchOptions: _.debounce(function (search, loading) {
      // Search only if 3 or more characters
      if (_.size(search) < 3) {
        return;
      }

      loading(true);

      const params = {
        filter_by: "label", // field name to be filtered, (value or label)
        search: search, // text to be filtered
      };

      this.domain
        .filterApiResource(this.apiResource, params)
        .then((response) => (this.filteredOptions = response.data))
        .catch((error) => {})
        .finally(() => loading(false));
    }, 350),

    appendToDomain() {

      // Check if domain exists
      if (!_.has(this.domain.domains, this.apiResource)) {
        return;
      }

      // Check if internalValue is a number
      if (!_.isNumber(this.internalValue) || _.isNaN(this.internalValue)) {
        return;
      }

      // Check if value exists in filteredOptions
      const selectedItem = _.find(this.filteredOptions, { value: this.internalValue });
      if (selectedItem) {
        // Append items if not exists
        const exists = _.some(this.domain.domains[this.apiResource], { value: selectedItem.value });
        if (!exists) {
          this.domain.domains[this.apiResource].push(selectedItem);
        }
      }
    }
  },
  computed: {
    optionStyle() {
      if (this.isMobile()) {
        return {
          "max-width": "30%",
        };
      }
    },
  },
  watch: {
  internalValue(newValue) {
    if (newValue) {
      this.appendToDomain();
    }
  },
},
};
</script>
<!--
<docs>
```vue
<template>
    <div class="container">
        <form-field-select
            v-model="inputValue"
            name="field-name"
            :options="options"
        >
        </form-field-select>
        <small>Selecionado: {{ inputValue }}</small>
    </div>
</template>

<script>
    export default {
      data() {
        return {
            inputValue: '',
            options : [
                {
                    label: 'Opção 1',
                    value: 'Value1'
                },
                {
                    label: 'Opção 2',
                    value: 'Value2'
                },
            ]
        };
      }
    }
</script>
```
</docs>
-->
