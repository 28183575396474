<template>
  <div
    class="d-flex flex-column gap-4 py-6 px-4 align-items-center parsing block"
  >
    <template v-if="step == 1">
      <div>
        <DocumentAddIcon size="2.5x" class="text-primary" />
      </div>
      <input
        type="file"
        style="display: none"
        ref="curriculumUpload"
        @change="onFilePicked"
      />
      <span class="text-center">
        {{ $t("parsing.send_file") }}
      </span>
      <b-button variant="primary" pill @click="onPickFile">
        {{ $t("parsing.search_file") }}
      </b-button>
    </template>

    <template v-else-if="step == 2">
      <div>
        <DocumentTextIcon size="2.5x" class="text-primary" />
      </div>
      <span class="text-center">
        {{ this.fileObject ? this.fileObject.name : "" }}
      </span>
      <b-button variant="primary" pill @click="startUploadFile">
        {{ $t("parsing.start_fill") }}
      </b-button>
    </template>
    <template v-else-if="step == 3">
      <div>
        <b-spinner style="opacity: 0.1" variant="outline-primary" />
      </div>
      <span class="text-center">
        {{ this.fileObject ? this.fileObject.name : "" }}
      </span>
      <span class="font-weight-semibold">
        {{ $t("parsing.read_file") }}
        <!-- Preenchando os campos... -->
      </span>
    </template>
    <template v-else-if="step == 4">
      <div style="width: 2.5em; height: 2.5em" class="text-primary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
          />
        </svg>
      </div>
      <span class="font-weight-semibold">
        {{ $t("parsing.ready") }}
      </span>
      <span class="text-center">
        {{ $t("parsing.check_the_info") }}
      </span>
      <b-link href="#" @click="restart">
        {{ $t("parsing.send_another_file") }}
        <!-- Preenchando os campos... -->
      </b-link>
    </template>
  </div>
</template>


<script>
import * as _ from "lodash-es";
import { useDomain } from "@state/domain/domain";
import { useCurriculum } from "@state/user/curriculum";
import { DocumentAddIcon, DocumentTextIcon } from "@vue-hero-icons/solid";
import { BLink } from "bootstrap-vue";

export default {
  name: "ParsingWidget",
  components: {
    DocumentAddIcon,
    DocumentTextIcon,
    BLink,
  },
  data() {
    return {
      fileObject: null,
      step: 1,
    };
  },
  created() {
    this.curriculum = useCurriculum();
    this.domain = useDomain();
  },
  methods: {
    restart() {
      this.step = 1;
    },
    onPickFile() {
      this.$refs.curriculumUpload.click();
    },
    onFilePicked(event) {
      this.step = 2;
      this.fileObject = event.target.files[0];

      console.warn(this.fileObject);

      this.$emit("selected", this.fileObject);
    },
    startUploadFile() {
      this.$root.loading = true;

      const event = new Event("before-upload", {
        cancelable: true,
      });

      let formData = new FormData();

      formData.append("file", this.fileObject);

      this.curriculum
        .sendCurriculumToParsing(formData)
        .then((response) => {
          if (response.data) {
            _.merge(this.curriculum, response.data.curriculum);

            this.curriculum.address.street_type =
              response.data.curriculum.address.street_type;
            this.curriculum.address.street =
              response.data.curriculum.address.street;
            this.curriculum.address.district =
              response.data.curriculum.address.district;
            this.curriculum.address.country =
              response.data.curriculum.address.country;
            this.curriculum.address.state =
              response.data.curriculum.address.state;
            // NECESSARY BECAUSE THE CITIES LIST MUST BE LOADED FIRST
            this.$nextTick(() => {
              this.curriculum.address.city = response.data.curriculum.address.city;

              // Force vuejs reactivity, due dynamic validations on step2
              this.curriculum.experiences = [...this.curriculum.experiences];
              this.curriculum.academic = [...this.curriculum.academic];
              this.curriculum.languages = [...this.curriculum.languages];
              this.curriculum.computing = { ...this.curriculum.computing };
            });
          }

          this.$root.loading = false;
        })
        .finally(() => {
          this.$root.loading = false;
        });

      const status = this.$emit("before-upload", event);

      this.step = 3;

      setTimeout(() => {
        this.$emit("after-upload");
        this.step = 4;
      }, 2000);
    },
  },
};
</script>
<!--
<docs>
```vue
<template>
    <div>
        <ParsingWidget
            @selected="logEvent('selected', $event)"
            @before-upload="logEvent('before-upload', $event)"
            @after-upload="logEvent('after-upload', $event)"
            />
        <ul>
            <li v-for="event in events">{{ event }} </li>
        </ul>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                events : []
            }
        },
        methods : {
            logEvent(name, event) {
                this.events.push(`[${name}]`)

                return false;
            }
        }
    }
</script>
```
</docs>
-->
