import Vue from "vue";
import axios from "./../plugins/axios.js";
import router from "./../router";
import auth from "@websanova/vue-auth/src/v2.js";
//import auth                  from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import driverAuthBearer from "@websanova/vue-auth/dist/drivers/auth/bearer.js";
import driverHttpAxios from "@websanova/vue-auth/dist/drivers/http/axios.1.x.js";
// import driverHttpVueResource from '@websanova/vue-auth/dist/drivers/http/vue-resource.1.x.js';
import driverRouterVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js";

// can be override in method calls
Vue.use(auth, {
    plugins: {
        http: axios, // Axios
        // http: Vue.http, // Vue Resource
        router: router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        // http: driverHttpVueResource, // Vue Resource
        router: driverRouterVueRouter,
    },
    options: {
        rolesKey: "roles",
        //notFoundRedirect: {name: 'user-account'},
        loginData: {
            url: import.meta.env.VITE_SSO_URL + "/auth/login",
            method: "POST",
            fetchUser: true,
        },
        logoutData: {
            url: import.meta.env.VITE_SSO_URL + "/auth/logout",
            method: "POST",
            redirect: "/login",
            makeRequest: true,
        },
        // @todo MOVE THE FETCH FROM portal to SS0)
        fetchData: {
            url: import.meta.env.VITE_SSO_URL + "/auth/user",
            method: "GET",
            enabled: true,
        },
        //fetchData: { url: '/auth/user', method: 'GET', enabled: true },
        //refreshData: { url: 'token/refresh', method: 'GET', enabled: true, interval: 30 }
        registerData: {
            url: import.meta.env.VITE_SSO_URL + "/auth/register",
            method: "POST",
            enabled: true,
        },
        notFoundRedirect : {path: "/"},
    },
    //tokenDefaultName: 'laravel_token',
    //tokenStore: ['localStorage'],
    //rolesVar: 'role',
    //registerData: {url: 'backend/auth/register', method: 'POST', redirect: '/login'},
});
