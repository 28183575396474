/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";

import CookieConsent from "./plugins/CookieConsent";

// pinia (state management)
import { createPinia, PiniaVuePlugin } from "pinia";
Vue.use(PiniaVuePlugin);

//Vue Clipboard
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

//Vue number animation
import VueNumber from "vue-number-animation";
Vue.use(VueNumber);

// axios
import axios from "./plugins/axios.js";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

// Vue Router
import router from "./router";
Vue.router = router;

//import "./plugins/matomo";

//import "./plugins/mixpanel";

// vue-i18n
import { i18n } from "./plugins/i18n";

// vee-validate
import "./plugins/validate";

// // Set Vue authentication
import "./plugins/auth2";

import "./plugins/bootstrap";

/**
 * @todo remover ícones de outras libs
 */
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
// /* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// /* import specific icons */
import { faWheelchair } from "@fortawesome/free-solid-svg-icons";
// /* add icons to the library */
library.add(faWheelchair);
// /* add font awesome icon component */
Vue.component("fa-icon", FontAwesomeIcon);

import { ToastPlugin } from "bootstrap-vue";
Vue.use(ToastPlugin);

import { ModalPlugin } from "bootstrap-vue";
Vue.use(ModalPlugin);

/**
 * @todo Remover vuesax
 */
// Vuesax Component Framework
//import Vuesax from "vuesax";
//Vue.use(Vuesax);
//

//** @todo Mapear componentes utilizados no DISC e Survey */
import { vsPopup, vsRadio, vsTextarea, vsRow, vsCol } from "vuesax";
Vue.use(vsPopup);
Vue.use(vsRadio);
Vue.use(vsRow);
Vue.use(vsCol);
Vue.use(vsTextarea);

import "./plugins/validate";
//Vue.use(ModalPlugin)

import { mask } from "vue-the-mask";
Vue.directive("mask", mask);



// Globally Registered Components
import "./plugins/globalComponents.js";

// Vuex Store
//import store from './store/store'

// Vuexy Admin Filters
import "./filters/filters";

//import ServicePlugin from '@services/Plugin'
//Vue.use(ServicePlugin)
/*
Vue.mixin({
  props: [
    'service'
  ],
});
*/

import installGa from "./plugins/ga.js";
import installGtm from "./plugins/gtm.js";
import installRollbar from "./plugins/rollbar.js";
import installEqualweb from "./plugins/equalweb.js";

Vue.config.productionTip = true;

const pinia = createPinia();

import { useCompany } from "@state/company";
import { useLocale } from "@state/system/locale";
import { useTheme } from "@state/system/theme";
//import { useWebsocket } from "@state/system/websocket";

import App from "./App.vue";
// const auth = useAuth();
// auth.installRoutesHooks();
// auth.load();

window.app = new Vue({
    pinia,
    router,
    i18n,
    CookieConsent,
    render: (h) => h(App),
    data() {
        return {
            loading: false,
            initialLoading: true,
        };
    },
    mounted() {
        this.company.loadData().then((data) => {
            installRollbar(data);
            installGtm(data);
            installGa(data);
            installEqualweb(data)
            this.initialLoading = false;
            this.loading = false;
            //this.websocket.setCompanyKey(data.company_key);
            //this.websocket.connect();
        });
        this.locale.detect();
    },
    setup() {
        //const auth = useAuth();
        const company = useCompany();
        const locale = useLocale();
        const theme = useTheme();
        //const websocket = useWebsocket();

        return {
            //auth,
            company,
            theme,
            locale,
            //websocket,
        };
    },
});

//Vue.use(i18n)

app.$mount("#app");
