import Vue from "vue";
import Rollbar from "rollbar";
import * as _ from "lodash-es";

const environment = BACKEND.env;

export default function (config) {
    // if (_.has(config, 'flags.use_rollbar') && config.flags.use_rollbar) {
    if (environment == "master" && _.has(config, 'flags.use_rollbar') && config.flags.use_rollbar) {

        var transformer = function(payload) {
            /**
             * @todo Add the user data to the payload
             */
            //payload.data = { person: { id: 1, username: 'test' } };
        };

        Vue.prototype.$rollbar = new Rollbar({
            accessToken: "a9a47e67a1964930a3173c70f9f11a0c",
            captureUncaught: true,
            captureUnhandledRejections: true,
            transform: transformer,
            payload: {
                environment: BACKEND.env,
                instance_id : config.instance_id,
                company_name : config.company_name,

                //context: 'home/index',
                //trace_id: 'abc123',
                client: {
                    javascript: {
                        code_version: "1.0.0",
                        //source_map_enabled: true,
                        //guess_uncaught_frames: true
                    },
                },
            },
        });

        Vue.config.errorHandler = (err, vm, info) => {
            vm.$rollbar.error(err);
            throw err; // rethrow
        };
    } else if (environment == "local" || environment == "develop") {
    }
}
