import * as _ from "lodash-es";
import { defineStore } from "pinia";
import Http from "@/plugins/axios";

const COURSES = "/domains/courses";
const READ = "/profile/curriculum";
const CHECK_CPF = "/auth/check-cpf";
const CREATE = "/profile/curriculum";
const PERSIST = "/profile/curriculum";
const ADDRESS = "/domains/address/zipcode";
const PROGRESS = "/profile/curriculum/progress";
const CV_EXPRESS_LOCALSTORAGE = "curriculum_express";
const CHECK_REGISTRATION = "/auth/check-registration";
const GET_REGISTRATION_DATA = "/auth/get-registration-data";
const SEND_CURRICULUM = "/profile/curriculum/cv-to-parsing";
const VALIDATE_FILE_WITH_OCR = "/profile/admissional/validate-file-with-ocr";
const VALIDATE_FILE_WITH_IA = "/profile/admissional/validate-file-with-ia";
const GENERATE_BANK_LETTER = "/profile/curriculum/generate-bank-letter";
const CREATE_VW_CANDIDATES = "/profile/curriculum/create-vw-candidates";
const SHOW_PARSING = "/profile/curriculum/show-parsing"

export const useCurriculum = defineStore("user", {
    // other options...
    state: () => {
        return {
            // all these properties will have their type inferred automatically
            sensitive: {
                username: "",
                password: "",
                password_confirmation: "",
            },
            personal: {
                is_foreigner: false,
                origin_country: "",
                document_type_id: "",
                document_number: "",
                avatar: null,
                accepted_terms: false,
                name: "",
                assumed_name: "",
                cpf: "",
                rg: "",
                birth_date: "",
                gender: "u",
                organ_rg: null,
                state_rg: null,
                civil_status: null,
                children: null,
                cellphone: "",
                email: null,
                special_needs: "",
                special_needs_details: null,
                primary_email: "",
                primary_email_confirmation: "",
                secondary_email: "",
                business_phone: "",
                home_phone: "",
                site: "",
                message_with: "",
            },
            address: {
                person_id: null,
                address_id: null,
                address_type: "h",
                zip_code: "",
                street: "",
                number: null,
                complement: null,
                district: null,
                city: null,
                state: null,
                country: null,
                references: null,
                street_type: null,
                latitude: null,
                longitude: null,
                geocoord: null,
                geocoord_precision: null,
            },
            professional: {
                interest_areas: [],
                interest_regions: [],
                interest_levels: [],
                has_professional_history: "",
                cover_letter: null,
                intended_occupation: [],
                salary_intended: 0,
                available_trip: "",
                folder_id: null,
                suggested_occupation: null,
                suggested_function: null,
            },
            experiences: [],
            education: {
                has_schooling_level: "",
                has_computer_knowledge: "",
                has_languages: "",
                has_additional_training: "",
                schooling_level: null,
                school_file_id: null,
            },
            academic: [],
            training: [],
            languages: [],
            computing: {},
            additional: {
                has_abroad_experience: false,
            },
            presentation: {},
            ctps: {
                has_ctps: false,
                has_pis: false,
            },
            voter: {
                has_voter_registration: false,
            },
            armed_forces: {
                has_certificate_armed_forces: false,
            },
            cnh: {
                has_cnh: false,
            },
            bank: {
                has_bank: false,
            },
            dependents: [],
            epi: {},
            benefits: {
                has_transports: false,
            },
            cnpj: {
                has_cnpj: true,
            },
            contacts: {},
            forms: {
                has_contribution_sindical: false,
            },
            extra: {},
            progress: {
                loaded: false,
            },
            transports: {},
            diversity: {
                racial: "",
                assumed_name: "",
                gender_identity: "",
                gender_orientation: ""
            }

        };
    },
    actions: {
        applyChanges(changes) {
            this.$patch(changes);
        },
        showParsing() {
            return Http.get(SHOW_PARSING);
        },
        async read(data) {
            return Http.get(READ).then(({ data }) => {
                this.$state = Object.assign({}, data);
            });
        },
        async getCoursesByCollege(college_id) {
            return Http.post(COURSES, college_id);
        },
        async create() {
            return Http.put(CREATE, this.$state);
        },
        async persist(data) {
            return Http.put(PERSIST, data);
        },
        async newAccount(credentials) {
            return Http.post('/profile/new-account', {credentials, curriculum: this.$state});
        },
        async createVwCandidates() {
            return Http.get(CREATE_VW_CANDIDATES);
        },
        async loadProgress(type) {
            return Http.post(PROGRESS, { type: type }).then(({ data }) => {
                this.$patch((state) => {
                    state.progress.loaded = true;

                    _.set(state.progress, type, data);
                });
            });
            // @todo Load the progress of required fields, bases on "type" (admissional,curriculum)
            // this.$patch((state) => {
            //     state.progress.loaded = true;

            //     _.set(state.progress, type, {
            //         max: 140,
            //         current: 45,
            //         personal : {
            //             max: 49,
            //             current: 34,
            //         },
            //         benefits : {
            //             max: 2,
            //             current: 2,
            //         }
            //     });
            // });
        },
        async validateFileWithOcr(data) {
            return Http.post(VALIDATE_FILE_WITH_IA, data);
        },
        async checkCpf(data) {
            return Http.post(CHECK_CPF, data);
        },
        async checkRegistration(data) {
            return Http.post(CHECK_REGISTRATION, data);
        },
        async getRegistrationData(data) {
            return Http.post(GET_REGISTRATION_DATA, data);
        },
        async sendCurriculumToParsing(data) {
            return Http.post(SEND_CURRICULUM, data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        },
        setAddressBasedOnZipcode(zipcode) {
            return new Promise((resolve, reject) => {
                return Http.post(ADDRESS, { zipcode })
                    .then((response) => resolve(response.data.address))
                    .catch(() => reject());
            });
        },

        getStep1ExpressDataFromLocal() {
            const payload = localStorage.getItem(CV_EXPRESS_LOCALSTORAGE);
            return payload ? JSON.parse(payload) : {};
        },

        setStep1ExpressDataToLocal(payload) {
            return localStorage.setItem(
                CV_EXPRESS_LOCALSTORAGE,
                JSON.stringify(payload)
            );
        },

        removeStep1ExpressDataFromLocal() {
            return localStorage.removeItem(CV_EXPRESS_LOCALSTORAGE);
        },

        resetState() {
            //this.removeStep1ExpressDataFromLocal();
            this.$reset();
        },
        generateBankAccountLetter(data) {
            return Http.post(GENERATE_BANK_LETTER, data, {
                responseType: "blob",
            });
        },
    },
});
